<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="credit-card" />
				<span>交易</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 交易记录 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">
			<a-form class="ant-advanced-search-form" :form="form" @submit="handleSearch">
				<a-row :gutter="24">
					<a-col :span="4">
						<a-form-item :label="`设备编号：`">
							<a-input placeholder="请输入设备编号" v-model="searchData.number" />
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-form-item :label="`设备名称：`">
							<a-input placeholder="请输入设备名称" v-model="searchData.name" />
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-form-item :label="`交易单号：`">
							<a-input placeholder="请输入交易单号" v-model="searchData.trade_no" />
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-form-item :label="`交易类型：`">
							<a-select v-model="searchData.type" placeholder="请选择交易类型">
								<a-select-option v-for="(item,index) in typeList" :key="index" :value="index"> {{item}}
								</a-select-option>
							</a-select>
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-form-item :label="`支付方式：`">
							<a-select v-model="searchData.pay_type" placeholder="请选择支付方式">
								<a-select-option v-for="(item,index) in paytypeList" :key="index" :value="index">
									{{item}}
								</a-select-option>
							</a-select>
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-form-item :label="`状态：`">
							<a-select v-model="searchData.status" placeholder="请选择状态">
								<a-select-option v-for="(item,index) in statusList" :key="index" :value="index">
									{{item}}
								</a-select-option>
							</a-select>
						</a-form-item>
					</a-col>
					<a-col :span="5">
						<a-form-item :label="`选择日期：`">
							<a-range-picker :disabledDate="disabledDate" :ranges="{ '今天': [moment(), moment()] }"
								:key="pickerKey" @change="handleChangeDate" style="width: 120px" format="YYYY-MM-DD" />
						</a-form-item>
					</a-col>
					<a-col :span="3">
						<a-button type="primary" @click="getList"> 查询 </a-button>
						<a-button :style="{ marginLeft: '8px' }" @click="handleReset">
							重置
						</a-button>
					</a-col>
				</a-row>
			</a-form>
			<div class="search-result-list">
				<div class="table-header-box">
					<div class="left">
						<h3 class="title">交易记录</h3>
					</div>
					<div class="right">
						<a-button type="primary" class="marginr20" @click="exportData">
							导出
						</a-button>
					</div>
				</div>
				<a-table :columns="columns" @change="handleTableChange" :pagination="{total: total_page * 10}"
					:data-source="list" rowKey="tran_id" size="small">
					<span slot="btn" slot-scope="text, record">
						<span style="color: #1890ff;cursor: pointer;" v-if="record.type == 1"
							@click="showInModel(record)">查看详情</span> 
						<span style="color: #1890ff;cursor: pointer;" v-if="record.type == 2"
							@click="showOutModel(record)">查看详情</span>
						<span style="color: #ff0000;cursor: pointer; margin-left: 10px;" v-if="record.do_status != 0"
							@click="showTodoModel(record)">交易处理</span>
						<span style="color: #ff0000;cursor: pointer; margin-left: 10px;" v-if="record.type == 2 && (record.status==2 || record.status==3)"
							@click="showToOutModel(record)">退款处理</span>
					</span>
				</a-table>
			</div>
		</div>

		<a-modal :title="todoTitle" :footer="null" :visible="todoModel" @ok="handleOk" @cancel="handleCancel">
			<a-spin v-if="todoStop == 1" :spinning="spinning">
				<div style="display:  flex;justify-content:  center;align-items:  center;height: 100px;">
					<a-button @click="todo" type="primary" style="margin-right: 40px;">
						手工处理
					</a-button>
					<a-button type="primary" @click="getTrDetail">
						线上处理
					</a-button>
				</div>
			</a-spin>
			<div v-if="todoStop == 2">
				<a-form class="ant-advanced-search-form">
					<a-form-item :label="`收款人实名：`">
						<a-input placeholder="请输入支付宝收款人实名" v-model="modelData.realname" />
					</a-form-item>
					<a-form-item :label="`支付宝账号：`">
						<a-input placeholder="请输入支付宝账号" v-model="modelData.account" />
					</a-form-item>
					<div  style="display:  flex;justify-content:  center;align-items:  center;padding: 20px 0;">
						<a-button @click="handleZfbCancel" style="margin-right: 40px;">
							取消处理
						</a-button>
						<a-button type="primary" @click="zfbHandle">
							确定处理
						</a-button>
					</div>
				</a-form>
			</div>
		</a-modal>

		<a-modal :title="toOutTitle" :footer="null" :visible="toOutModel" @ok="handleOutOk" @cancel="handleOutCancel">
			<a-form class="ant-advanced-search-form">
					<a-form-item :label="`退款金额：`">
						<a-input-number placeholder="请输入退款金额" style="width: 300px" v-model="modelOutData.refund_amount" :min="0" :precision="2"/>
					</a-form-item>
					<div  style="display:  flex;justify-content:  center;align-items:  center;padding: 20px 0;">
						<a-button @click="handleOutCancel" style="margin-right: 40px;">
							取消处理
						</a-button>
						<a-button type="primary" @click="outHandle">
							确定处理
						</a-button>
					</div>
				</a-form>
		</a-modal>

		<a-modal v-model="inDetailShow" width="800px" title="详细信息" :footer="null">
			<a-spin :spinning="inDetailLoading">
				<a-form :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }" style="margin: 0 auto; width: 100%">
					<a-row :gutter="24">
						<a-col :span="12">
							<a-form-item label="交易单号：" >
								<a-input v-model="info.trade_no" :disabled="true"/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="交易类型：">
								<a-input v-model="info.type_name" :disabled="true"/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="设备名称：" >
								<a-input v-model="info.name" :disabled="true"/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="设备编号：" >
								<a-input v-model="info.number" :disabled="true"/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="交易金额：">
								<a-input v-model="info.amount" :disabled="true"/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="支付渠道：">
								<a-input v-model="info.pay_type" :disabled="true"/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="存入1元(枚)：">
								<a-input v-model="info.in100" :disabled="true"/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="存入5角(枚)：">
								<a-input v-model="info.in50" :disabled="true"/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="存入1角(枚)：">
								<a-input v-model="info.in10" :disabled="true"/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="交易状态：">
								<a-input v-model="info.status_txt" :disabled="true"/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="交易备注：">
								<a-input v-model="info.remark" :disabled="true"/>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="交易时间：">
								<a-input v-model="info.add_time" :disabled="true"/>
							</a-form-item>
						</a-col>
					</a-row>
				</a-form>
			</a-spin>
		</a-modal>

<a-modal v-model="outDetailShow" width="800px" title="详细信息" :footer="null">
	<a-spin :spinning="outDetailLoading">
		<a-form :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }" style="margin: 0 auto; width: 100%">
			<a-row :gutter="24">
				<a-col :span="12">
					<a-form-item label="交易单号：" >
						<a-input v-model="info.trade_no" :disabled="true"/>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item label="交易类型：">
						<a-input v-model="info.type_name" :disabled="true"/>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item label="设备名称：" >
						<a-input v-model="info.name" :disabled="true"/>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item label="设备编号：" >
						<a-input v-model="info.number" :disabled="true"/>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item label="交易金额：">
						<a-input v-model="info.amount" :disabled="true"/>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item label="支付渠道：">
						<a-input v-model="info.pay_type" :disabled="true"/>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item label="取1元(枚)：">
						<a-input v-model="info.out100" :disabled="true"/>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item label="取5角(枚)：">
						<a-input v-model="info.out50" :disabled="true"/>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item label="取1角(枚)：">
						<a-input v-model="info.out10" :disabled="true"/>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item label="实际取1元：">
						<a-input v-model="info.out100_real" :disabled="true"/>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item label="实际取5角：">
						<a-input v-model="info.out50_real" :disabled="true"/>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item label="实际取1角：">
						<a-input v-model="info.out10_real" :disabled="true"/>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item label="交易状态：">
						<a-input v-model="info.status_txt" :disabled="true"/>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item label="交易备注：">
						<a-input v-model="info.remark" :disabled="true"/>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item label="交易时间：">
						<a-input v-model="info.add_time" :disabled="true"/>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item label="退款金额：">
						<a-input v-model="info.refund_amount" :disabled="true"/>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item label="退款状态：">
						<a-input v-model="info.rstatus_txt" :disabled="true"/>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item label="退款时间：">
						<a-input v-model="info.refund_time" :disabled="true"/>
					</a-form-item>
				</a-col>
			</a-row>
		</a-form>
	</a-spin>
</a-modal>
	</div>
</template>

<script>
	import moment from 'moment';

	const columns = [{
			title: "ID",
			dataIndex: "tran_id",
		},
		{
			title: "交易单号",
			dataIndex: "trade_no",
		},
		{
			title: "终端编号",
			dataIndex: "number",
		},
		{
			title: "交易类型",
			dataIndex: "tname",
		},
		{
			title: "交易结果",
			dataIndex: "remark",
			width: 250,
		},
		{
			title: "发生时间",
			dataIndex: "add_time",
		},
		{
			title: "支付时间",
			dataIndex: "trade_time",
		},
		{
			title: "金额(元)",
			dataIndex: "amount",
		},
		{
			title: "交易账户",
			dataIndex: "third_uid",
		},
		{
			title: "状态",
			dataIndex: "status_txt",
		},
		{
			title: "操作",
			width: 150,
			dataIndex: "btn",
			scopedSlots: {
				customRender: 'btn'
			},
		},
	];
	import http from '../http'
	export default {
		data() {
			return {
				inDetailShow:false,
				outDetailShow:false,
				todoModel: false,
				toOutModel: false,
				todoStop: 1,
				todoData: {},
				todoTitle: '选择处理方式',
				toOutTitle: '确定退款金额',
				modelData: {
					realname: '',
					account: ''
				},
				modelOutData:{
					refund_amount:0,
				},
				spinning: false,
				
				expand: false,
				form: this.$form.createForm(this, {
					name: "advanced_search"
				}),
				columns,
				searchData: {
					page: 1,
					node_id: undefined,
					bank_id: undefined,
					status: 0,
					pay_type: 0,
					type: 0,
					trade_no: '',
					start_date: '',
					end_date: '',
					number: '',
					name: ''
				},
				list: [],
				total_page: 0,

				// 表格选择的数据, 存储的是id
				selectedRowKeys: [],

				pickerKey: Math.random(),
				bankTreeData: [],
				outletsList: [],
				typeList: ['全部', '回收硬币', '兑换硬币'],
				paytypeList: ['全部', '支付宝支付', '微信支付'],
				statusList: ['全部', '失败', '成功'],
				
				inDetailLoading: false,
				outDetailLoading: false,
				info:[],
			};
		},
		computed: {
			count() {
				return this.expand ? 11 : 7;
			},
		},
		updated() {
			console.log("updated");
		},
		created() {
			this.getBankTreeData()
			this.getOutletsList()
			this.getList()
		},
		methods: {
			moment,
			showTodoModel(data) {
				this.todoStop = 1
				this.todoTitle = '选择处理方式'
				this.modelData = {
					realname: '',
					account: ''
				}
				this.todoData = data
				this.todoModel = true
			},
			showToOutModel(data) {
				this.modelOutData = {
					refund_amount: data.refund_amount
				}
				this.todoData = data
				this.toOutModel = true
			},
			showInModel(data) {
				this.inDetailShow = true
				this.inDetailLoading = true
				http({
					method: 'post',
					url: '/api.transaction.detail/',
					data: {
						tran_id: data.tran_id
					}
				}).then((res) => {
					if (res.code == '200') {
						this.info =  res.data
						this.inDetailLoading = false
					}
				})
			},
			showOutModel(data) {
				this.outDetailShow = true
				this.outDetailLoading = true
				http({
					method: 'post',
					url: '/api.transaction.detail/',
					data: {
						tran_id: data.tran_id
					}
				}).then((res) => {
					if (res.code == '200') {
						this.info =  res.data
						this.outDetailLoading = false
					}
				})
			},
			handleOk() {

			},
			handleOutOk() {

			},
			handleCancel() {
				this.todoModel = false
			},
			handleOutCancel() {
				this.toOutModel = false
			},
			handleZfbCancel () {
				this.todoStop = 1
				this.todoTitle = '选择处理方式'
				this.modelData = {
					realname: '',
					account: ''
				}
			},
			todo() {
				this.$confirm({
					title: '确定已线下处理过？',
					onOk:() => {
						http({
							method: 'post',
							url: '/api.transaction.todo/',
							data: {
								tran_id: this.todoData.tran_id
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('数据更新成功');
								this.getList()
								this.handleCancel()
							} else {
								this.$message.error(res.message);
							}
						})
					},
					onCancel() {
						console.log('Cancel');
					},
					class: 'test',
				});
			},
			getTrDetail () {
				this.spinning = true
				http({
					method: 'post',
					url: '/api.transaction.sdetail/',
					data: {
						tran_id: this.todoData.tran_id
					}
				}).then((res) => {
					if (res.code == '200') {
						this.spinning = false
						if (res.data.do_status == 1) {
							this.todoTitle = '选择支付宝线上转账'
							this.todoStop = 2
						} else  if (res.data.do_status == 2) {
							this.$confirm({
								title: '确定线上转账处理吗？',
								onOk:() => {
									http({
										method: 'post',
										url: '/api.transaction.linedo/',
										data: {
											tran_id: this.todoData.tran_id,
										}
									}).then((res) => {
										if (res.code == '200') {
											this.$message.success('线上转账处理成功');
											this.getList()
											this.handleCancel()
										} else {
											this.$message.error(res.message);
										}
									})
								},
								onCancel() {
									console.log('Cancel');
								},
								class: 'test',
							});
						}
					} else {
						this.$message.error(res.message);
					}
				})
			},
			zfbHandle () {
				if (this.modelData.realname == '') {
					this.$message.error('请输入收款人实名');
					return
				}
				if (this.modelData.account == '') {
					this.$message.error('请输入支付宝账号');
					return
				}
				this.$confirm({
					title: '确定支付宝线上转账吗？',
					onOk:() => {
						http({
							method: 'post',
							url: '/api.transaction.alipay.linedo/',
							data: {
								tran_id: this.todoData.tran_id,
								account: this.modelData.account,
								real_name: this.modelData.realname,
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('支付宝转账处理成功');
								this.getList()
								this.handleCancel()
							} else {
								this.$message.error(res.message);
							}
						})
					},
					onCancel() {
						console.log('Cancel');
					},
					class: 'test',
				});
			},
			outHandle () {
				if (this.modelOutData.refund_amount == 0) {
					this.$message.error('请输入退款金额');
					return
				}
				this.$confirm({
					title: '确定退款吗？',
					onOk:() => {
						http({
							method: 'post',
							url: '/api.transaction.refund/',
							data: {
								tran_id: this.todoData.tran_id,
								refund_amount: this.modelOutData.refund_amount,
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('退款处理成功');
								this.getList()
								this.handleCancel()
							} else {
								this.$message.error(res.message);
							}
						})
					},
					onCancel() {
						console.log('Cancel');
					},
					class: 'test',
				});
			},
			disabledDate(current) {
				return current && current >= moment().subtract("days"); //当天之前的不可选，不包括当天
				//return current && current < moment().endOf(‘day’);当天之前的不可选，包括当天
			},
			handleTableChange(pagination, filters, sorter) {
				this.searchData.page = pagination.current
				this.getList();
			},
			handleSearch(e) {
				e.preventDefault();
				this.form.validateFields((error, values) => {
					console.log("error", error);
					console.log("Received values of form: ", values);
				});
			},

			// 表格选择
			onSelectChange(selectedRowKeys) {
				console.log('selectedRowKeys changed: ', selectedRowKeys);
				this.selectedRowKeys = selectedRowKeys;
			},

			handleReset() {
				this.pickerKey = Math.random()
				this.searchData = {
					page: 1,
					node_id: undefined,
					bank_id: undefined,
					status: 0,
					pay_type: 0,
					trade_no: '',
					type: 0,
					start_date: '',
					end_date: '',
					number: '',
					name: ''
				}
				this.getList()
			},

			// 日期解析
			handleChangeDate(date) {
				this.searchData.start_date = this.moment(date[0]).format('YYYY-MM-DD')
				this.searchData.end_date = this.moment(date[1]).format('YYYY-MM-DD')
			},

			// 列表
			getList() {
				http({
					method: 'post',
					url: '/api.transaction.list/',
					data: {
						...this.searchData,
					}
				}).then((res) => {
					this.list = res.data.list
					this.total_page = res.data.total_page
				})
			},
			//导出
			exportData(){
				let newData = this.searchData
				delete newData.page
				http({
					method: 'post',
					url: '/api.transaction.export.data/',
					data: {
						...newData,
					}
				}).then((res) => {
					if(res.code=='200'){
						window.open(res.data);
					}
				})
			},
			// 获取网点
			getOutletsList() {
				http({
					method: 'post',
					url: '/api.bank.node.treelist/',
					data: {
						page: 1,
						name: '',
						bank_id: 0
					}
				}).then((res) => {
					this.outletsList = res.data
				})
			},
			// 获取银行类型
			getBankTreeData() {
				http({
					method: 'post',
					url: '/api.bank.list/',
					data: {
						page: 1,
						parent_id: 0
					}
				}).then((res) => {
					this.bankTreeData = this.dataDealwith(res.data)
				})
			},
			dataDealwith(treeData) {
				function setGrayNode(data) { //遍历树  获取id数组
					for (var i = 0; i < data.length; i++) {
						data[i] = {
							value: data[i].bank_id,
							key: data[i].bank_id,
							title: data[i].name,
							children: data[i].list || []
						} //子节点传给另一个函数专门用来改数据
						if (data[i].children && data[i].children.length) { // 如果当前节点有子节点，就递归调用一次
							setGrayNode(data[i].children);
						}
					}
				}
				setGrayNode(treeData)
				return treeData;
			},
		},
	};
</script>
<style>
	.ant-advanced-search-form {
		padding: 24px;
		background: #fbfbfb;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}

	.search-list-box .ant-form {
		max-width: none;
	}

	.search-list-box .search-result-list {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 80px;
	}
</style>
